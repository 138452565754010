/**
 * @name: 设备管理-机械臂设备信息管理接口文件
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 设备管理-机械臂设备信息管理接口文件
 * @update: 2024-07-18 14:45
 */
import api from '@/request/api'
import {IPageRes} from "@/apis/page";
import {IDeviceArm, IDeviceArmAllocation, IDeviceArmDetailParams, IDeviceArmParams} from "@/apis/device/arm-list/types";

export const deviceArmQueryApi = (params: IDeviceArmParams) => api.get<IPageRes<IDeviceArm[]>>('/admin/device/query', params)

export const deviceArmCreateApi = (deviceNum: number) => api.postJ('/admin/device/create', {deviceNum})

export const deviceArmAllocationApi = (data: IDeviceArmAllocation[]) => api.postJ('/admin/device/batchAllocation', data)

export const deviceArmModifyStatusApi = (id: string) => api.postJ('/admin/device/updateStatus', {id})

export const deviceArmDetailListApi = (params: IDeviceArmDetailParams) => api.get('/admin/device/queryPartnerPage', params)

export const deviceArmExportApi = (params: IDeviceArmParams) => api.get('/admin/device/export', params, 'blob')

export const deviceResetApi = (id: string) => api.get('/admin/device/restoreFactorySettings', {id})
